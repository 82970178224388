define("discourse/plugins/discourse-data-explorer/discourse/models/query", ["exports", "@ember/object", "discourse/lib/get-url", "discourse/models/rest"], function (_exports, _object, _getUrl, _rest) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class Query extends _rest.default {
    static updatePropertyNames = ["name", "description", "sql", "user_id", "created_at", "group_ids", "last_run_at"];
    params = {};
    constructor() {
      super(...arguments);
      this.param_info?.resetParams();
    }
    get downloadUrl() {
      return (0, _getUrl.default)(`/admin/plugins/explorer/queries/${this.id}.json?export=1`);
    }
    get hasParams() {
      // When saving, we need to refresh the param-input component to clean up the old key
      return this.param_info.length && !this.updateing;
    }
    static #_ = (() => dt7948.n(this.prototype, "hasParams", [(0, _object.computed)("param_info", "updateing")]))();
    beforeUpdate() {
      this.set("updateing", true);
    }
    afterUpdate() {
      this.set("updateing", false);
    }
    resetParams() {
      const newParams = {};
      const oldParams = this.params;
      this.param_info.forEach(pinfo => {
        const name = pinfo.identifier;
        if (oldParams[pinfo.identifier]) {
          newParams[name] = oldParams[name];
        } else if (pinfo["default"] !== null) {
          newParams[name] = pinfo["default"];
        } else if (pinfo["type"] === "boolean") {
          newParams[name] = "false";
        } else if (pinfo["type"] === "user_id") {
          newParams[name] = null;
        } else if (pinfo["type"] === "user_list") {
          newParams[name] = null;
        } else if (pinfo["type"] === "group_list") {
          newParams[name] = null;
        } else {
          newParams[name] = "";
        }
      });
      this.params = newParams;
    }
    updateProperties() {
      const props = this.getProperties(Query.updatePropertyNames);
      if (this.destroyed) {
        props.id = this.id;
      }
      return props;
    }
    createProperties() {
      if (this.sql) {
        // Importing
        return this.updateProperties();
      }
      return this.getProperties("name");
    }
  }
  _exports.default = Query;
});